import { useCallback, useState } from 'react';
import fetchConversion from './fetchConversion';

export default function useConversion() {
  const [conversion, setConversion] = useState({
    filteredGuestCount: 0,
    totalGuestCount: 0,
    totalUserCount: 0,
    totalConsultationCount: 0,
    inquiryGuestCount: 0,
    inquiryUserCount: 0,
  });

  const loadConversion = useCallback(async () => {
    const data = await fetchConversion();

    setConversion(data);
  }, []);

  const {
    filteredGuestCount,
    totalGuestCount,
    totalUserCount,
    totalConsultationCount,
    inquiryGuestCount,
    inquiryUserCount,
  } = conversion;
  const inquiryConversion = ((inquiryGuestCount / filteredGuestCount) * 100).toFixed(2);
  const signupConversion = ((inquiryUserCount / inquiryGuestCount) * 100).toFixed(2);

  return {
    loadConversion,
    filteredGuestCount,
    totalGuestCount,
    totalUserCount,
    totalConsultationCount,
    inquiryGuestCount,
    inquiryUserCount,
    inquiryConversion,
    signupConversion,
  };
}
