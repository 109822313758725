import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Consultation from './Consultation';
import ConsultationsHead from './components/dashBoard/ConsultationsHead';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid black;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5em;
`;

const Row = styled.div`
  display: flex;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2em;
  width: 15em;
`;

function UppointTriangle({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <button type="button" onClick={onClick}>
      &#9650;
    </button>
  );
}

function DownpointTriangle({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <button type="button" onClick={onClick}>
      &#9660;
    </button>
  );
}

export default function Consultations({
  consultations,
  isInquiryLengthAscend,
  isQuestionCountAscend,
  isAverageQuestionLengthAscend,
  toggleInquiryLengthOrder,
  toggleQuestionCountOrder,
  toggleAverageQuestionLengthOrder,
}: {
  consultations: Consultation[];
  isInquiryLengthAscend: boolean;
  isQuestionCountAscend: boolean;
  isAverageQuestionLengthAscend: boolean;
  toggleInquiryLengthOrder: () => void;
  toggleQuestionCountOrder: () => void;
  toggleAverageQuestionLengthOrder: () => void;
}) {
  const navigate = useNavigate();

  const handleClickConsultationId = ({
    e,
    consultationId,
  }:{
    e: React.MouseEvent<HTMLDivElement>;
    consultationId: string;
  }) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(`/consultations/${consultationId}`, '_blank');
    } else {
      navigate(`/consultations/${consultationId}`);
    }
  };
  return (
    <>
      <ConsultationsHead />
      <Table>
        <Header>
          <Cell>시간</Cell>
          <Cell>상담id</Cell>
          <Cell>A.K.A.</Cell>
          <Cell>전화번호</Cell>
          <Cell>
            문의 길이 (
            {isInquiryLengthAscend
              ? <UppointTriangle onClick={toggleInquiryLengthOrder} />
              : <DownpointTriangle onClick={toggleInquiryLengthOrder} />}
            )
          </Cell>
          <Cell>
            추가질문 횟수(
            {isQuestionCountAscend
              ? <UppointTriangle onClick={toggleQuestionCountOrder} />
              : <DownpointTriangle onClick={toggleQuestionCountOrder} />}
            )
          </Cell>
          <Cell>
            추가질문 평균 글자수(
            {isAverageQuestionLengthAscend
              ? <UppointTriangle onClick={toggleAverageQuestionLengthOrder} />
              : <DownpointTriangle onClick={toggleAverageQuestionLengthOrder} />}
            )
          </Cell>
        </Header>
        <Body>
          {consultations.map((c) => (
            <Row>
              <Cell>
                { dayjs(c.createdAt).format('M월DD일 HH시 mm분') || '-'}
              </Cell>
              <Cell
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => handleClickConsultationId({ e, consultationId: c.id })}
              >
                {c.id || '-'}
              </Cell>
              <Cell>
                {c.userAka || '-'}
              </Cell>
              <Cell>
                {c.phoneNumber || '-'}
              </Cell>
              <Cell>
                {c.inquiryLength}
              </Cell>
              <Cell>
                {c.questionCount}
              </Cell>
              <Cell>
                {c.averageQuestionLength}
              </Cell>
            </Row>
          ))}
        </Body>
      </Table>
    </>
  );
}
