import { createRoot } from 'react-dom/client';

import App from './App';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw Error('루트 엘리먼츠가 없어요!!');
}

const root = createRoot(rootElement);

root.render(<App />);
