import axios from 'axios';

const { NOKE_TRACK_API_URL } = process.env;

const baseURL = NOKE_TRACK_API_URL;

export default async function textSearchConsultations(query: string) {
  const { data } = await axios.get(`${baseURL}/text-search/consultations?text=${query}`);
  return data;
}
