import thanosClient from '../service/thanosClient';

export default function useLogin() {
  const login = async ({ code, email }:{
    code: string; email: string;
  }) => {
    try {
      const { data } = await thanosClient.post('/sessions', {
        code, email,
      });

      const { accessToken } = data;

      localStorage.setItem('ACCESS_TOKEN', accessToken);
    } catch (e) {
      throw new Error('인증 실패');
    }
  };

  return login;
}
