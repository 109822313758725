import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useQueryParams from '../hooks/useQueryParams';
import useLogin from '../hooks/useLogin';

const Container = styled.div`
  max-width: 1024px;
  margin: 3em auto;

  h1 {
    font-size: 2em;
    margin-bottom: 1em;
    line-height: 1.5;
    text-align: center;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  height: 80vh;

  img {
    width: 50%;
  }
`;

const OtpForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    background-color: #4b52ff;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  input {
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

export default function OtpPage() {
  const [code, setCode] = useState<string>('');

  const { email } = useQueryParams();

  const navigate = useNavigate();

  const login = useLogin();

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setCode(value);
  };

  useEffect(() => {
    if (!email) {
      navigate('/login');
    }
  }, [email, navigate]);

  const handleClick = async () => {
    try {
      await login({
        code,
        email: email!,
      });

      navigate('/');
    } catch (e) {
      alert('인증에 실패했습니다. 코드를 확인해주세요.');
    }
  };

  return (
    <Container>
      <Body>
        <OtpForm>
          <p>이메일로 전송된 암호를 입력해주세요</p>
          <Field>
            <input placeholder="xxx xxx" type="text" onInput={handleInput} />
          </Field>
          <button type="button" onClick={handleClick}>
            입력하기
          </button>
        </OtpForm>
      </Body>
    </Container>
  );
}
