import { useEffect } from 'react';
import useConsultations from '../useConsultations';
import Dashboard from '../Dashboard';
import Consultations from '../Consultations';

export default function DashboardPage() {
  const {
    totalInquiryCount,
    longInquiryCount,
    consultations,
    loadConsultations,
    isInquiryLengthAscend,
    isQuestionCountAscend,
    isAverageQuestionLengthAscend,
    toggleInquiryLengthOrder,
    toggleQuestionCountOrder,
    toggleAverageQuestionLengthOrder,
  } = useConsultations();

  useEffect(() => {
    loadConsultations();
  }, [loadConsultations]);

  return (
    <div>
      <h1>
        노크 어드민
      </h1>
      <Dashboard
        totalInquiryCount={totalInquiryCount}
        longInquiryCount={longInquiryCount}
      />
      <Consultations
        consultations={consultations}
        isInquiryLengthAscend={isInquiryLengthAscend}
        isQuestionCountAscend={isQuestionCountAscend}
        isAverageQuestionLengthAscend={isAverageQuestionLengthAscend}
        toggleInquiryLengthOrder={toggleInquiryLengthOrder}
        toggleQuestionCountOrder={toggleQuestionCountOrder}
        toggleAverageQuestionLengthOrder={toggleAverageQuestionLengthOrder}
      />
    </div>
  );
}
