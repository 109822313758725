import { useEffect } from 'react';
import styled from 'styled-components';
import useConversion from './useConversion';

const Container = styled.div`
  ul {
    padding-left: 1em;
    border-bottom: 1px solid black;
  }
`;

const Row = styled.li`
  display: flex;
  margin-bottom: .5em;
  padding-bottom: .5em;
  
  > span {
    min-width: 15em;
  }
`;

export default function Dashboard({
  totalInquiryCount,
  longInquiryCount,
}: {
  totalInquiryCount: number;
  longInquiryCount: number;
}) {
  const {
    loadConversion,
    filteredGuestCount,
    totalGuestCount,
    totalUserCount,
    totalConsultationCount,
    inquiryGuestCount,
    inquiryUserCount,
    inquiryConversion,
    signupConversion,
  } = useConversion();

  const longInquiryRatio = ((longInquiryCount / totalInquiryCount) * 100)
    .toFixed(2);

  useEffect(() => {
    loadConversion();
  }, [loadConversion]);

  return (
    <Container>
      <h2>대시보드</h2>
      <ul>
        <Row>
          <span>
            전체 방문자:
            {' '}
            {totalGuestCount}
          </span>
        </Row>
        <Row>
          <span>
            전체 회원가입 수:
            {' '}
            {totalUserCount}
          </span>
        </Row>
        <Row>
          <span>
            전체 상담건수:
            {' '}
            {totalConsultationCount}
          </span>
        </Row>
        <Row>
          <span>150자 이상 문의수 / 전체 문의수:</span>
          {' '}
          <span>
            {longInquiryRatio}
            % (
            {longInquiryCount}
            {' '}
            /
            {' '}
            {totalInquiryCount}
            )
          </span>
        </Row>
        <Row>
          <span>
            방문자
            {' '}
            {'->'}
            {' '}
            상담을 진행한 방문자
            {' '}
            {'->'}
            {' '}
            상담을 진행한 사용자(회원가입):
            {' '}
          </span>
          <span>
            {filteredGuestCount}
            {' '}
            {`--(${inquiryConversion}%)-->`}
            {' '}
            {inquiryGuestCount}
            {' '}
            {`--(${signupConversion}%)-->`}
            {' '}
            {inquiryUserCount}
          </span>
        </Row>
      </ul>
    </Container>
  );
}
