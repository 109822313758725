import thanosClient from './thanosClient';

const validateEmail = async (email: string) => {
  try {
    await thanosClient.get(`/access-code?email=${email}`);
  } catch (e) {
    throw Error('등록되지 않은 이메일 입니다');
  }
};

export default validateEmail;
