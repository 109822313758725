import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import dayjs from 'dayjs';

import groupBy from 'lodash/groupBy';

import useConsultation from '../useConsultation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 24em 1em 24em;
  gap: .5em;
  
`;

const BorderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  `;

const MessageBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: .5em;  
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
`;

const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
`;

const BorderTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin: 0 1em;
  white-space: nowrap;

`;
const Content = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  min-width: 3em;
`;

const ContentQuestion = styled.p`
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  min-width: 3em;
`;

const ContentAnswer = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  min-width: 3em;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: gray;
  flex-grow: 1;
`;

const Wall = styled.span`
  margin-inline: 1em;
`;

export default function ConsultationDetailPage() {
  const { consultationId } = useParams();
  const { consultation, loadConsultation } = useConsultation();

  useEffect(() => {
    if (!consultationId) {
      return;
    }
    loadConsultation(consultationId);
  }, [consultationId, loadConsultation]);

  if (!consultation) {
    return (
      <div>
        로딩중입니다!
      </div>
    );
  }
  const {
    userAka, createdAt, mainConsultation, additionalConsultation,
  } = consultation;

  return (
    <Container>
      <SubTitle>
        <strong>{userAka}</strong>
        <Wall>||</Wall>
        <strong>문의 생성시간 : </strong>
        { dayjs(createdAt).format('M월DD일 HH시 mm분') }
        <Wall>||</Wall>
        <strong>조언 생성시간 : </strong>
        { dayjs(mainConsultation.advice.createdAt).format('M월DD일 HH시 mm분') }
      </SubTitle>
      <Border />
      <Title>
        {mainConsultation.inquiry.title}
      </Title>
      <Border />
      <SubTitle>
        문의 내용 :
      </SubTitle>
      <Content>
        {mainConsultation.inquiry.content}
      </Content>
      <Border />
      <SubTitle>
        조언 :
      </SubTitle>
      <Content>
        {
          mainConsultation.advice.content
        }
      </Content>
      <div>
        <BorderBox>
          <Border />
          <BorderTitle>  추가 질문들  </BorderTitle>
          <Border />
        </BorderBox>
        {Object.entries(
          groupBy(additionalConsultation, (m) => dayjs(m.createdAt).format('M월DD일')),
        ).map(([date, messages]) => (
          messages.map((m, i) => (
            <div key={m.createdAt}>
              {i === 0 && (
              <SubTitle>
                {date}
              </SubTitle>
              )}
              <MessageBox>
                <Content>
                  { dayjs(m.createdAt).format('HH:mm') }
                </Content>
                <Content>
                  {m.type === 'answer' ? '답변:' : '질문:'}
                </Content>
                {m.type === 'answer' ? <ContentAnswer>{m.content}</ContentAnswer> : <ContentQuestion>{m.content}</ContentQuestion>}
              </MessageBox>
            </div>
          ))
        ))}
      </div>
    </Container>
  );
}
