import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import useAuthorize from '../hooks/useAuthorize';

export default function RouteGuard() {
  const authorize = useAuthorize();

  const navigate = useNavigate();

  useEffect(() => {
    authorize().catch(() => {
      navigate('/login');
    });
  });

  return (
    <Outlet />
  );
}
