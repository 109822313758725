import { useCallback, useState } from 'react';

import fetchConsultation from './fetchConsultation';
import ConsultationDetail from './ConsultationDetail';

export default function useConsultation() {
  const [consultation, setConsultation] = useState<ConsultationDetail | null>(null);

  const loadConsultation = useCallback(async (consultationId: string) => {
    const fetched = await fetchConsultation(consultationId);
    setConsultation(fetched);
  }, []);
  return {
    consultation,
    loadConsultation,
  };
}
